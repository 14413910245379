<template>
  <div class="m_professional">
    <m-title title="2023年度专业科目" />
    <div class="statistics">
      <div class="statistics_card left">
        <div style="font-size: 16px">进行中...</div>
        <div style="font-size: 12px">
          {{ Statistics && Statistics.endTime }}截止
        </div>
      </div>
      <div class="statistics_card right">
        <div>
          <van-circle
            :size="40"
            :stroke-width="150"
            color="#FFFFFF"
            layer-color="rgba(255, 255, 255, 0.4)"
            :rate="rate"
            :speed="100"
          />
        </div>

        <div style="margin-left: 5px">
          <div style="font-size: 16px">{{ rate.toFixed(0) + "%" }}</div>
          <div style="font-size: 12px">学习进度</div>
        </div>
      </div>
    </div>
    <div class="card_panel">
      <div class="panel">
        <div class="value">{{ Statistics && Statistics.keChengShu }}</div>
        <div class="label">
          {{ Statistics && Statistics.isClass ? "学时数" : "课程数" }}
        </div>
      </div>
      <div class="panel">
        <div class="value">{{ Statistics && Statistics.weiWanCheng }}</div>
        <div class="label">未完成</div>
      </div>
      <div class="panel">
        <div class="value">{{ Statistics && Statistics.daiKaoShi }}</div>
        <div class="label">待考试</div>
      </div>
      <div class="panel">
        <div class="value">{{ Statistics && Statistics.kaoShiTongGuo }}</div>
        <div class="label">考试通过</div>
      </div>
    </div>
    <m-title title="课程列表" />
    <div class="courseList">
      <capsule-tab @click="selectCapsule" :list="List" />
      <div class="list">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
        >
          <div class="item" v-for="(item, index) in dataList" :key="index">
            <div
              v-if="dataList.length > 0"
              @click="
                keepAliveNavigation(
                  '/majorPlayerPage',
                  {
                    courseId: item.courseId,
                    studyCourseId: item.studyCourseId,
                  },
                  true,
                  false
                )
              "
            >
              <div class="title">
                <div class="left-tag">
                  <van-tag
                    v-if="item.ifQualified === 1"
                    class="tag"
                    type="success"
                    color="#559a59"
                    text-color="#ffffff"
                    >{{ item.合格 }}</van-tag
                  >
                  <van-tag
                    v-else
                    class="tag"
                    :type="item.status === 1 ? 'success' : 'warning'"
                    color="#559a59"
                    text-color="#ffffff"
                    >{{ item.status === 1 ? "待考试" : "未学习" }}</van-tag
                  >
                </div>
                <div class="title" :title="item.courseName">
                  {{ item.courseName }}
                </div>
              </div>
              <div class="userpsn">
                <svg-icon icon="icon-jiangshi" />
                {{ item.unitSpeaker }}
              </div>
              <div class="progress">
                <van-progress
                  :show-pivot="false"
                  track-color="#D8D8D8"
                  color="#549A54"
                  :percentage="item.percent"
                  stroke-width="8"
                />
              </div>
              <div class="timelength">已学{{ item.percent }}%</div>
            </div>
            <van-empty v-if="dataList.length <= 0" description="暂无课程" />
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>
<script>
import MTitle from "@/components/m_title.vue";
import SvgIcon from "@/components/svgIcon.vue";
import CapsuleTab from "@/components/capsuleTab.vue";
import {
  getCoursepPublicDemandAccount,
  getCoursepUserCourseList,
} from "@/api/specializedController";
import * as storage from "@/utils/storage";

export default {
  name: "m_professional",
  components: {
    CapsuleTab,
    SvgIcon,
    MTitle,
  },
  data() {
    return {
      loading: false,
      finished: false,
      dataList: [],
      rate: 0,
      filterType: 0,
      List: [
        {
          label: "全部",
          value: 0,
        },
        {
          label: "未完成",
          value: 1,
        },
        {
          label: "待考试",
          value: 2,
        },
        {
          label: "考试通过",
          value: 3,
        },
      ],
      Statistics: {},
    };
  },
  computed: {
    yearId() {
      return storage.getStore("yearId");
    },
  },
  activated() {
    this.inspectRefresh(this.handleRefresh);
  },
  methods: {
    // 获取统计
    getStatistics() {
      var that = this;
      getCoursepPublicDemandAccount({ trainingPlanId: this.yearId }).then(
        (res) => {
          that.rate = res.data.xueXiJinDu;
          that.Statistics = res.data;
        }
      );
    },
    // 筛选项
    selectCapsule(item) {
      this.filterType = item.value;
      this.getCourseList();
    },
    // 获取列表
    getCourseList() {
      const that = this;
      that.loading = true;
      getCoursepUserCourseList({
        trainingPlanId: this.yearId,
        filterType: this.filterType,
        type: 0,
        ifExam: 0,
      }).then((res) => {
        that.dataList = res.data;
        this.finished = true;
        this.loading = false;
      });
    },
    handleRefresh() {
      this.getStatistics();
      this.getCourseList();
    },
  },
};
</script>
<style scoped lang="scss">
/deep/ .left-tag span {
  margin-right: 5px;
  border-radius: 5px;
}
.m_professional {
  height: 100%;
  width: 100%;

  .statistics {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
    margin: 10px auto;

    .statistics_card {
      padding: 10px 15px;
      background: #b50413;
      border-radius: 10px;
      color: #ffffff;
    }

    .left {
    }

    .right {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .card_panel {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-bottom: 15px;

    .panel {
      padding: 10px 12px;
      text-align: center;
      background: rgba(251, 154, 162, 0.2);
      border-radius: 8px;

      .label {
        font-size: 12px;
      }

      .value {
        font-size: 16px;
      }
    }
  }

  .courseList {
    background: #ffffff;
    border-radius: 8px;
    padding: 12px 10px;
    margin: 10px auto;

    .list {
      .item {
        padding: 12px 15px;
        background: #f4f6f8;
        border-radius: 4px;
        margin-bottom: 12px;

        .title {
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-content: center;
          justify-content: flex-start;
          align-items: center;

          .tag {
            padding: 2px 4px;
            white-space: nowrap;
          }

          .title {
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            font-size: 14px;
          }
        }

        .userpsn {
          color: #818496;
          font-size: 12px;
          margin-top: 5px;
        }

        .progress {
          margin-top: 12px;
        }

        .timelength {
          margin-top: 5px;
          color: #71b158;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
